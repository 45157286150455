import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import WatchlistActionModal from "./modal/WatchlistActionModal";
import TVChartContainer from "../TVChartContainer";

import Table from 'react-bootstrap/Table';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Badge from 'react-bootstrap/Badge';

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import { PulseLoader } from "react-spinners";
import { Doughnut } from "react-chartjs-2";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronRight,
	faEye,
} from '@fortawesome/free-solid-svg-icons';

import { formatDate } from '../../util/dateUtil';
import { formatDollars, formatBigNumber, formatPercentage } from '../../util/numberUtil';

const ccassBackgroundColor = [
	'#005394',
	'#A7FA42',
	'#FF7B00',
	'#0095A0',
	'#2BE3EA',
	'#005593',
	'#0099FF',
	'#417AAA',
	'#62BAFF',
	'#002A4A',
	'#ADB7C3',
];

const FinListData = ({ stock, exchange, forbidden, ccassData = null, mobile = false, watchlist, getFinList, getWatchlist }) => {
	const [ showOverlay, setShowOverlay ] = useState(false);
	const [ showWatchlistActionModal, setShowWatchlistActionModal ] = useState(false);

	const { t, i18n } = useTranslation();
	const { getAccessTokenSilently, userId } = useAuth0();

	const toggleOverlay = () => {
		if (!stock.mask) {
			setShowOverlay(!showOverlay);
		}
	};

	const toggleWatchlistActionModal = (event) => {
		if (event) {
			event.stopPropagation();
		}

		if (!stock.mask) {
			setShowWatchlistActionModal(!showWatchlistActionModal);
		}
	};

	const getStockCode = () => {
		if (exchange === "HKEX") {
			return "0".repeat(Math.max(0, 4 - stock.stockCode.length)) + stock.stockCode;
		}

		return stock.stockCode;
	};

	const getTVExchange = () => {
		switch (exchange) {
			case "JP":
				return "TSE";
			case "CN":
				return "SSE";
			default:
				return "";
		}
	}

	const renderOverlay = () => {
		return (
			<Offcanvas show={ showOverlay } onHide={ toggleOverlay } placement="end" scroll className="finlist-overlay">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<b className="me-2">{ getStockCode() }</b>
						{ i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName }
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div>
						<div>
							<b>{ i18n.language === "zh" && stock.subSectorZh !== "" ? stock.subSectorZh : stock.subSector }</b>
							<p className="mt-1">
								{ i18n.language === "zh" && stock.profileInfoZh !== "" ? stock.profileInfoZh : stock.profileInfo }
							</p>
						</div>
						{
							stock.fundamentalData ?
							<>
								<hr />
								<div>
									<Table responsive className="fundamental-data-table">
										<thead>
											<tr>
												<th colSpan={ 2 } className="text-center">{ t("finlist.data.past-8q-changes") }</th>
												<th colSpan={ 2 } className="text-center">{ t("finlist.data.eps") }</th>
												<th colSpan={ 2 } className="text-center">{ t("finlist.data.sales") }</th>
												<th colSpan={ 2 } className="text-center">{ t("finlist.data.npm") }</th>
											</tr>
										</thead>
										<tbody>
											{
												[1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
													var fundamentalData = stock.fundamentalData["q" + i];
													return (
														<tr key={ stock.stockCode + "-eps-q" + i } >
															<th>Q{ i }</th>
															<td className="text-right">
																{ fundamentalData.thisReportDate.toString().length === 8 ? formatDate(fundamentalData.thisReportDate.toString().substring(0, 4) + "-" + fundamentalData.thisReportDate.toString().substring(4, 6) + "-"  + fundamentalData.thisReportDate.toString().substring(6, 8)) : "--" }<br />
																{ fundamentalData.lastReportDate.toString().length === 8 ? formatDate(fundamentalData.lastReportDate.toString().substring(0, 4) + "-" + fundamentalData.lastReportDate.toString().substring(4, 6) + "-"  + fundamentalData.lastReportDate.toString().substring(6, 8)) : "--" }
															</td>
															<td className="text-right text-nowrap">
																{ formatDollars(fundamentalData.thisEps) }<br />
																{ formatDollars(fundamentalData.lastEps) }
															</td>
															<td className="text-right text-nowrap">
																{ formatPercentage(fundamentalData.epsChange, true, true) }
															</td>
															<td className="text-right text-nowrap">
																{ formatBigNumber(fundamentalData.thisSales, true) }<br />
																{ formatBigNumber(fundamentalData.lastSales, true) }
															</td>
															<td className="text-right text-nowrap">
																{ formatPercentage(fundamentalData.salesChange, true, true) }
															</td>
															<td className="text-right text-nowrap">
																{ formatPercentage(fundamentalData.thisNpm * 100, false) }<br />
																{ formatPercentage(fundamentalData.lastNpm * 100, false) }
															</td>
															<td className="text-right text-nowrap">
																{ formatPercentage(fundamentalData.npmChange, true, true) }
															</td>
														</tr>
													)
												})
											}
										</tbody>
									</Table>
								</div>
							</> : null
						}
						<hr />
						<div>
							{
								(exchange === "HKEX" || exchange === "TW" || exchange === "JP") ?
								<TVChartContainer
									symbol={ exchange + ":" + stock.stockCode }
									containerId={ "finlist-chart-" + stock.stockCode + "-sm" }
									autosize={ true }
									className="finlist-chart"
									disabledFeatures={[
										"header_saveload",
										"header_symbol_search",
										"symbol_search_hot_key",
										"header_chart_type",
										"header_settings",
										"header_compare",
										"header_undo_redo",
										"header_screenshot",
										"header_fullscreen_button",
										"control_bar",
										"timeframes_toolbar",
										"main_series_scale_menu",
									]}
									getAccessTokenSilently={ getAccessTokenSilently }
									userId={ userId }
								/>
								: <div className="finlist-chart">
									<AdvancedRealTimeChart
										interval="D"
										symbol={ getTVExchange() + ":" + stock.stockCode }
										style="0"
										autosize
										withdateranges={ true }
										allow_symbol_change={ false }
										save_image={ false }
										hide_side_toolbar={ true }
									/>
								</div>
							}
						</div>
						{
							exchange === "HKEX" ?
								ccassData && ccassData.length > 0 ?
								<>
									<hr />
									<div>
										<b>{ t("finlist.data.ccass.top-10-shareholder") }</b>
										<Table responsive className="ccass-data-table">
											<thead>
												<tr>
													<th colSpan={ 2 } >{ t("finlist.data.ccass.participant-name") }</th>
													<th className="text-end">{ t("finlist.data.ccass.shareholding") }</th>
													<th className="text-end">%</th>
												</tr>
											</thead>
											<tbody>
												{
													ccassData.filter(shareholdingData => shareholdingData.shareholding !== -1).map((shareholdingData, i) => (
														<tr key={ stock.stockCode + "-ccass-" + i } >
															<th>
																<div className="d-flex justify-content-center">
																	<div className={ "ccass-box ccass-box-" + (i + 1) } ></div>
																</div>
															</th>
															<td>
																{ shareholdingData.participantName }
															</td>
															<td className="text-end">
																{ formatBigNumber(shareholdingData.shareholding) }
															</td>
															<td className="text-end">
																{ formatPercentage(shareholdingData.shareholdingPercent, false) }
															</td>
														</tr>
													))
												}
											</tbody>
										</Table>
										<div className="d-flex justify-content-center my-3">
											<div className="ccass-data-chart">
												<Doughnut data={{
													labels: ccassData.map(shareholdingData => shareholdingData.participantName),
													datasets: [
														{
															label: "CCASS data",
															data: ccassData.map(shareholdingData => shareholdingData.shareholdingPercent),
															backgroundColor: ccassBackgroundColor,
														},
													],
												}} options={{
													plugins: {
														legend: {
															display: false,
														},
													},
												}} />
											</div>
										</div>
										<div className="d-flex justify-content-center">
											<p className="ccass-information-date">{ t("finlist.information-as-at", { date: formatDate(ccassData[0].shareholdingDate) })}</p>
										</div>
									</div>
								</> : <>
									<hr />
									<div className="d-flex flex-column align-items-center">
										<div className="my-2">
											<PulseLoader color="#005493" speedMultiplier={ 0.6 } size={ 7.5 } />
										</div>
										{ t("finlist.loading-ccass") }
									</div>
								</>
							: null
						}
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		);
	};

	const renderDesktopRow = () => {
		return (
			<tr className={ "clickable " + (stock.mask ? " mask-finlist-stock" : "") + (stock.isLowTurnover ? " finlist-low-turnover" : "") + (stock.buyPoint === -1 ? "" : " highlight") } onClick={ toggleOverlay } >
				<td className="text-center">
					<FontAwesomeIcon icon={ faEye } className={ "watchlist-icon " + (stock.watchlistData.length > 0 ? "active-watchlist-icon" : "inactive-watchlist-icon" ) } onClick={ toggleWatchlistActionModal } />
					<Badge pill bg="finetic-orange"></Badge>
				</td>
				<td>
					<div>
						<b>{ getStockCode() }</b><br />
						{ i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName }
					</div>
				</td>
				<td className="text-right">
					{ formatDollars(stock.close) }
				</td>
				<td className="text-right">
					{ formatPercentage(stock.closeChange) }
				</td>
				<td className="text-right">
					{ formatBigNumber(stock.volume) }
				</td>
				<td className="text-right">
					{ formatPercentage(stock.volumeChange) }
				</td>
				<td className="text-right">
					<div className="d-flex justify-content-center">
						<Badge pill bg="finetic-blue">{ stock.percivalScore }</Badge>
					</div>
				</td>
				<td className="text-right">
					{ stock.buyPoint === -1 ? "--" : formatDollars(stock.buyPoint) }
				</td>
				<td className={ "text-center" } >
					{ stock.patternType !== "NIL" ? t("finlist.pattern." + stock.patternType) : "--" }
				</td>
				<td>
					<FontAwesomeIcon icon={ faChevronRight } />
				</td>
			</tr>
		);
	};

	const renderMobileRow = () => {
		return (
			<div className={ "finlist-stock-card my-2 py-1 px-2" + (stock.buyPoint !== -1 ? " highlight" : "") + (stock.isLowTurnover ? " finlist-low-turnover" : "") } onClick={ toggleOverlay } >
				<div className="row">
					<div className="col-11">
						<div className="row">
							<div className="col-10 ticker">
								<span className={ "stock-code" + (stock.mask ? " mask-finlist-stock" : "") } >{ getStockCode() }</span>
								<span className={ "stock-name" + (stock.mask ? " mask-finlist-stock" : "") }> { i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName }</span>
							</div>
							<div className="col-2 text-end">
								<FontAwesomeIcon icon={ faEye } className={ "watchlist-icon " + (stock.watchlistData.length > 0 ? "active-watchlist-icon" : "inactive-watchlist-icon" ) } onClick={ toggleWatchlistActionModal } />
							</div>
						</div>

						<div className="row details">
							<div className="col-6">
								<div className="d-flex justify-content-between pr-10">
									<div className="label">{ t("finlist.data.close") }:</div>
									<span className={ stock.mask ? " mask-finlist-stock" : "" } >{ formatDollars(stock.close) }</span>
								</div>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-between pl-10">
									<div className="label">{ t("finlist.data.volume") }:</div>
									<span className={ stock.mask ? " mask-finlist-stock" : "" } >{ formatBigNumber(stock.volume) }</span>
								</div>
							</div>
						</div>
						<div className="row details">
							<div className="col-6">
								<div className="d-flex justify-content-between pr-10">
									<div className="label">{ t("finlist.data.change-abbr") }:</div>
									<span className={ stock.mask ? " mask-finlist-stock" : "" } >{ formatPercentage(stock.closeChange) }</span>
								</div>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-between pl-10">
									<div className="label">{ t("finlist.data.change-abbr") }:</div>
									<span className={ stock.mask ? " mask-finlist-stock" : "" } >{ formatPercentage(stock.volumeChange) }</span>
								</div>
							</div>
						</div>
						<div className="row details">
							<div className="col-6">
								<div className="d-flex justify-content-between pr-10">
									<div className="label">{ t("finlist.data.buy-point") }:</div>
									<span className={ stock.mask ? " mask-finlist-stock" : "" } >{ stock.buyPoint === -1 ? "--" : formatDollars(stock.buyPoint) }</span>
								</div>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-between pl-10">
									<div className="label">{ t("finlist.data.percival-score") }:</div>
									<span className={ stock.mask ? " mask-finlist-stock" : "" } >{ stock.percivalScore }</span>
								</div>
							</div>
						</div>
						{
							stock.patternType !== "NIL" ?
								<div className="row details">
									<div className="col-6">
										<div className="d-flex justify-content-between pr-10">
											<div className="label">{ t("finlist.data.pattern") }:</div>
											<span className="pattern-name">{ t("finlist.pattern." + stock.patternType + "-abbr") }</span>
										</div>
									</div>
								</div> : null
						}
					</div>
					<div className="col-1 d-flex justify-content-end align-items-center">
						<FontAwesomeIcon icon={ faChevronRight } className="expand-chevron" />
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<WatchlistActionModal show={ showWatchlistActionModal } toggle={ toggleWatchlistActionModal } stock={ stock } exchange={ exchange } watchlist={ watchlist } getFinList={ getFinList } getWatchlist={ getWatchlist } />
			{ mobile ? renderMobileRow() : renderDesktopRow() }
			{ renderOverlay() }
		</>
	);
};

export default FinListData;