import historyProvider from './historyProvider';

const apiRoot = process.env.REACT_APP_FINETIC_API_URL + 'tvchart';

function tvApi(getAccessTokenSilently, onPatternCallback, streamingApi)
{
	this.getAccessTokenSilently = getAccessTokenSilently;
	this.onPatternCallback = onPatternCallback;
	this.streamingApi = streamingApi;

	this.setWidget = (widget) => {
		this.widget = widget;
	}

	this.onReady = async (callback) => {
		var url = apiRoot + '/config';

		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		})
			.then(response => response.json())
			.then(json => callback(json.data));
	};

	this.searchSymbols = async (userInput, exchange, symbolType, onResultReadyCallback) => {
		var qs = {
			query: userInput,
			exchange: exchange,
			isSimulator: this.streamingApi != null,
		};

		var query = Object.keys(qs)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
             .join('&');

		var url = apiRoot + '/search?' + query;

		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		})
			.then(response => response.json())
			.then(json => {
				var results;

				var numberStockCode = json.data.filter(a => !isNaN(a.symbol)).sort((a, b) => Number(a.symbol) > Number(b.symbol));
				var charStockCode =  json.data.filter(a => isNaN(a.symbol)).sort((a, b) => ((a.symbol.indexOf(query.toUpperCase()) === -1 ? 999 : a.symbol.indexOf(query.toUpperCase())) + a.symbol.length) > ((b.symbol.indexOf(query.toUpperCase()) === -1 ? 999 : b.symbol.indexOf(query.toUpperCase())) + b.symbol.length));

				results = !isNaN(query) ? [ ...numberStockCode, ...charStockCode ] : [ ...charStockCode, ...numberStockCode ];

				onResultReadyCallback(results.slice(0, Math.min(results.length, 100)));
			});
	};

	this.resolveSymbol = async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		var qs = {
			symbol: symbolName,
			isSimulator: this.streamingApi != null,
			isGame: this.streamingApi != null ? this.streamingApi.isGame : false,
		};

		var query = Object.keys(qs)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
             .join('&');

		var url = apiRoot + '/symbols?' + query;

		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		})
			.then(response => response.json())
			.then(json => {
				onSymbolResolvedCallback(json.data);
			})
			.catch(err => onResolveErrorCallback(err));
	};

	this.getBars = async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		if (this.streamingApi) {
			this.streamingApi.getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback);
			return;
		}

		var from = periodParams.from * 1000;
		var to = periodParams.to * 1000;
		var firstDataRequest = periodParams.firstDataRequest;

		historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest, this.getAccessTokenSilently)
			.then(history => {
				onHistoryCallback(history.bars, { noData: history.noData, nextTime: history.nextTime });
			}).catch(err => {
				onErrorCallback(err);
			})
	};

	this.subscribeBars = (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
		if (this.streamingApi) {
			this.streamingApi.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback);
		}
	};

	this.unsubscribeBars = (subscriberUID) => {
		if (this.streamingApi) {
			this.streamingApi.unsubscribeBars(subscriberUID);
		}
	};

	this.getMarks = async (symbolInfo, from, to, onDataCallback, resolution) => {
		if (this.streamingApi) return;

		if (symbolInfo.name === "HKEX:HSI" || symbolInfo.name === "US:US500" || symbolInfo.name === "JP:N225" || symbolInfo.name === "CN:CSI300" || symbolInfo.name === "TW:TAIEX") {
			var qs = {
				from: from * 1000,
				to: to * 1000,
				symbol: symbolInfo.name,
			};
			
			var query = Object.keys(qs)
				.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
				.join('&');

			var url = apiRoot + '/marks?' + query;

			var accessToken = await this.getAccessTokenSilently({
				audience: process.env.REACT_APP_FINETIC_API_URL
			});

			fetch(url, {
				headers: {
					Authorization: "Bearer " + accessToken,
				}
			})
				.then(response => response.json())
				.then(json => onDataCallback(json.data));
		}

		var qs = {
			symbol: symbolInfo.name,
			from: from * 1000,
			to: to * 1000,
			resolution: resolution,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var url = apiRoot + '/patterns?' + query;

		fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		})
			.then(response => response.json())
			.then(json => this.onPatternCallback(json.data));
	};

	this.getTimescaleMarks = (symbolInfo, from, to, onDataCallback, resolution) => {
		// Optional
	};

	this.getServerTime = async (callback) => {
		var url = apiRoot + '/time';

		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		})
			.then(response => response.json())
			.then(json => callback(json.data));
	};
}

export default tvApi;
